import './bootstrap';
import Vue from 'vue';
import '../sass/auth.scss';

const app = new Vue({
    el: '#app',
    data: {
        message: "",
        email: "",
        password: "",
        remember: false,
        otp: "",

        alert: "",
        alertType: "",

        welcome: false,

        emailType: '',
        emailMessage: '',
        passwordType: '',
        passwordMessage: '',
        passwordToggle: 'password',
        otpMessage: '',

        otpneeded: false,
        oneLogin: false,
    },
    watch: {
        email: function (e) {
            if (e.includes('@persgroep') || e.includes('@dpgmedia')) {
                this.oneLogin = true;
            }
            else {
                this.oneLogin = false;
            }
        }
    },
    methods: {
        validateEmail: function () {
            const re = /.+@.+\..+$/;
            return re.test(this.email);
        },
        checkEmail: function () {
            if (this.validateEmail()) {
                this.emailType = 'success';
                this.emailMessage = '';
                return true;
            }
            this.emailType = 'error';
            this.emailMessage = 'This e-mailadress is not valid.'
            return false;
        },
        checkPassword: function () {
            if (this.password) {
                this.passwordType = 'success';
                this.passwordMessage = '';
                return true;
            }
            this.passwordType = 'error';
            this.passwordMessage = 'Fill in a password.'
            return false;
        },
        postLogin: function () {
            if (!this.checkEmail()) return;
            if (!this.checkPassword()) return;

            var self = this;
            axios.post('/login', {
                email: this.email,
                password: this.password,
                remember: this.remember,
                otp: this.otp,
                _token: window.csrfToken,
            }).then(function (response) {
                self.welcome = true;

                window.location = response.request.responseURL;
            }).catch(function (error) {
                if (error.response.status == 422 && error.response.data.error == "Otp required.") {
                    self.otpneeded = true;
                }

                if (error.response.status == 422 && error.response.data.error == "Otp wrong.") {
                    self.otpMessage = 'One time password is wrong.';
                }

                self.makeNotice(error.response.data.message, 'error');
            });
        },
        makeNotice: function (message, type) {
            this.alert = message;
            this.alertType = type;

            setTimeout(() => {
                this.alert = "";
                this.alertType = "";
            }, 4000);
        }
    },
    created: function () {
        if (this.email) { this.checkEmail(); }
        if (this.password) { this.checkPassword(); }
    },
    mounted() {
        document.querySelector('#app').style.opacity = 1;
    }
});